.navbar {
  background-color: #4b6296;
  font-size: 1.5vmin;
  font-family: Arial, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 9vh;
  width: 100%;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 9vh;
  width: 100%;
  padding: 0 5vw;
}

.nav-logo {
  display: flex;
  align-items: start;
  justify-content: start;
  max-height: 9vh;
  min-width: 10vw;
}

.head-logo {
  max-height: 9vh;
  object-fit: cover;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  height: 9vh;
  min-width: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 0.7vw;
}

.nav-item-login,
.nav-item-loggedin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9vh;
  min-width: 10vw;
  position: relative;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #29282e;
  font-weight: normal;
  min-width: 12vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%;
  left: 0;
}

.dropdown-content a {
  float: none;
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  color: #00baf3;
}

.nav-item-loggedin:hover .dropdown-content,
.dropdown:hover .dropdown-content,
.nav-item:hover .dropdown-content {
  display: block;
}

.nav-links {
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-bottom: 3px solid transparent;
}
.login-icon:hover {
  color: #00baf3;
}
.nav-item:hover,
.nav-item-loggedin:hover {
  background: #00baf3;
}

.logged-in-welcome {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 3px solid transparent;
}

.menu-icon {
  margin-left: 40vmin;
  display: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
}

@media screen and (max-width: 768px) {
  .navbar {
    font-size: 2.5vmin;
  }
  .nav-menu {
    display: none;
    right: -100%;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 9vh;
    right: 0;
    width: 50%;
    height: 50vh;
    background-color: #4b6296;
    transition: all 5s ease;
    align-items: center;
    justify-content: center;
  }

  .nav-item {
    min-height: 8vmin;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }

  .nav-item-login {
    min-height: 8vmin;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .login-icon:hover {
    color: white;
  }
  .nav-item-login:hover {
    background: #00baf3;
  }

  .nav-links {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .menu-icon {
    display: block;
  }

  .mobile-nav-links {
    color: #fff;
    /* width: 100vw; */
    text-align: center;
    padding: 1rem;
    border-bottom: 3px solid transparent;
  }

  .dropdown-content {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-menu.active .dropdown-content.show {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .dropdown-content a {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }

  .logged-in-welcome {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-item-loggedin {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .logged-in-welcome .dropdown-content {
    width: 100%;
  }
}
