/* src/Footer.css */
.footer {
  width: 100%;
  background-color: #1a1a1a;
  text-align: left;
  border-top: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15vh;
}

.footer-column {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  min-height: 10vh;
}

.footer-row {
  max-height: 3vh;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer p {
  font-family: Arial, sans-serif;
  margin: 1vmin;
  font-size: 1.3vmin;
  color: #ffffff;
}

.footer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  /* .footer {
    flex-direction: column;
    text-align: center;
  } */
}
