.college-cap-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.college-cap-container {
  background-color: white;
  display: flex;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
}

.college-cap-container h2 {
  font-size: 3.8vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}
.college-cap-header {
  text-align: center;
}
.college-cap-imgs {
  text-align: center;
  margin: 0 0 1vmin;
}

.college-cap-container img {
  margin-right: 0.5vmin;
}

.college-cap-container h3 {
  font-size: 2.5vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}

.college-cap-imgs img {
  height: 14vmin;
}

@media screen and (max-width: 768px) {
  .college-cap-container {
    width: 90vw;
  }
  .college-cap-imgs img {
    height: 10vmin;
  }
}
