.drone-program-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.drone-program-page label {
  font-size: 2vmin;
}

.drone-program-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
}

.drone-program-container table {
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.paragraph {
  text-align: left;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 2.2vmin;
  color: #000;
  text-decoration: none;
}

.registeration-form-container {
  padding: 10vmin 4vmin 4vmin 4vmin;
}

.registeration-form {
  border-radius: 1vmin;
  background-color: #f2f2f2;
  padding: 4vmin;
}

.drone-program-container input[type="text"],
.drone-program-container input[type="email"],
.drone-program-container select {
  width: 100%;
  padding: 2vmin;
  border: 1px solid #ccc;
  border-radius: 1vmin;
  box-sizing: border-box;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
  resize: vertical;
}

.drone-program-container input[type="submit"] {
  background-color: #2196f3;
  color: white;
  padding: 2vmin 3vmin;
  border: none;
  border-radius: 1vmin;
  cursor: pointer;
}

.registration-form-error {
  color: red;
  display: block;
  font-size: 1.5vmin;
  margin-bottom: 1vmin;
}

.drone-program-container input[type="submit"]:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.drone-program-container a {
  color: #337ab7;
  text-decoration: none;
}

.registeration-form-container h3 {
  font-size: 2.3vmin;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}
