.mission-statement-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.mission-statement-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
  line-height: 1.42857143;
}

@media screen and (max-width: 768px) {
  .mission-statement-container {
    width: 90vw;
  }
}
