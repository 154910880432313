.comments-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  z-index: 1000; /* Ensures the modal is on top */
}

.comments-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Black background with opacity */
  z-index: 999; /* Ensures the overlay is below the modal but above other content */
}

.comments-content {
  margin-bottom: 20px;
}
