.contact-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.contact-container {
  background-color: white;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
}

.contact-form-container {
  border-radius: 1vmin;
  background-color: #f2f2f2;
  padding: 4vmin;
}

.contact-container input[type="text"],
.contact-container input[type="email"],
.contact-container select {
  width: 100%;
  padding: 2vmin;
  border: 1px solid #ccc;
  border-radius: 1vmin;
  box-sizing: border-box;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
  resize: vertical;
}

.contact-container textarea {
  overflow: auto;
  width: 100%;
  padding: 2vmin;
  border: 1px solid #ccc;
  border-radius: 1vmin;
  box-sizing: border-box;
}

.contact-container input[type="submit"] {
  background-color: #2196f3;
  color: white;
  padding: 2vmin 3vmin;
  border: none;
  border-radius: 1vmin;
  cursor: pointer;
}

.contact-form-error {
  color: red;
  display: block;
  font-size: 1.5vmin;
  margin-bottom: 1vmin;
}

.contact-container input[type="submit"]:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.contact-page label {
  font-size: 2vmin;
}

@media screen and (max-width: 768px) {
  .contact-page {
    /* height: 80vh; */
  }
}
