.interschool-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.interschool-container {
  background-color: white;
  display: flex;
  width: 75vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
  text-align: center;
}

.interschool-container h2 {
  font-size: 3.8vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}

.interschool-paragragh {
  font-size: 2vmin;
  font-family: Arial, sans-serif;
}

.interschool-imgs {
  margin: 0 0 1vmin;
}

.interschool-imgs img {
  height: 35vmin;
}

@media screen and (max-width: 768px) {
  .interschool-container {
    width: 90vw;
  }

  /* .interschool-container table,
  .interschool-container tr {
    height: 45vh;
  } */
  .interschool-imgs img {
    height: 25vmin;
  }
}
