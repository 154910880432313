.create-edit-class-modal {
  inset: 8vh 0 0 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.318);
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-edit-class-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1%;
  width: 55%;
}
.create-edit-class-header h3 {
  font-size: 2vmin;
  font-weight: 800;
}
.create-edit-class-header {
  display: flex;
  justify-content: space-between;
  margin-top: 2vmin;
  width: 90%;
}

.create-edit-class-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 3vmin;
  height: fit-content;
}
.create-edit-class-content form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
  height: fit-content;
}

.create-edit-class-content span {
  font-size: 1.5vmin;
}

.create-edit-class-row {
  display: flex;
  align-items: flex-start;
  min-height: 4vmin;
}

.create-edit-class-content select {
  -webkit-appearance: menulist-button;
  font-size: 1.5vmin;
  width: 15vw;
  height: 3vmin;
}

.create-edit-class-row label {
  display: inline-block;
  width: 8vw;
  font-size: 1.5vmin;
  font-weight: 800;
}

.student-list,
.student-picker {
  width: 15vw;
}

.recurring-schedule,
.normal-schedule-content {
  display: flex;
  text-align: center;
  font-size: 1.5vmin;
  width: 65%;
}

.create-edit-class-save {
  margin: 3vmin;
  display: flex;
  justify-content: center;
}
.normal-schedule {
  width: 70%;
}
.normal-schedule-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.create-edit-class-modal .time-zone-picker,
.create-edit-class-modal .time-zone-picker-container {
  width: 12vw;
}

.css-b62m3t-container,
.css-13cymwt-control,
.css-t3ipsp-control {
  font-size: 1.2vmin;
  width: 12vw;
  height: 3vmin !important;
  min-height: 0 !important;
}

.css-1nmdiq5-menu,
.css-qr46ko {
  top: 70%;
  max-height: 20vmin !important;
  margin-top: 0px;
}

.css-17kckzk-MenuList,
.css-1n6sfyn-MenuList {
  height: 20vmin;
  max-height: 20vmin;
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 3vmin;
  padding: 0 !important;
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  padding: 0 !important;
}

.normal-schedule-row label {
  display: inline-block;
  width: 5vmin;
  font-size: 1.5vmin;
  font-weight: normal;
  align-self: center;
}

.schedule-section {
  display: flex;
  flex-direction: column;
  gap: 2vmin;
  width: 100%;
}

.schedule-row {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .create-edit-class-container {
    width: 100%;
  }

  .css-b62m3t-container,
  .css-13cymwt-control,
  .css-t3ipsp-control {
    font-size: 1.2vmin;
    width: 25vw;
    height: 3vmin !important;
    min-height: 0 !important;
  }

  .create-edit-class-content select {
    width: 25vw;
  }

  .create-edit-class-row label {
    width: 15vmin;
  }
}
