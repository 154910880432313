.college-case-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.college-case-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  line-height: 1.42857143;
}

.college-case-header {
  font-family: Arial, sans-serif;
  font-size: 3.4vmin;
  color: #555555;
  font-weight: bold;
  text-decoration: none;
  padding-bottom: 2vmin;
}

.college-case-container li {
  padding-bottom: 2vmin;
  list-style-type: none;
}

.college-case-content {
  font-family: Arial, sans-serif;
  color: #ff0000;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.8vmin;
}

.description {
  display: block;
  color: black;
  line-height: 1.4;
}

.description-container {
  width: 50%;
  padding: 0 2vmin;
  line-height: 1.4;
}

.img-container {
  width: 50%;
  padding: 0 2vmin;
}

@media screen and (max-width: 768px) {
  .college-case-container {
    width: 90vw;
  }
}
