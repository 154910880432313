.academy-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.academy-container {
  background-color: white;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
  display: flex;
}

.academy-side-nav {
  height: 24vmin;
  width: 20vmin;
  position: relative;
  float: left;
  z-index: 0;
  left: 0px;
  background: #eee;
  overflow: hidden;
}

.three-columns {
  font-size: 1.8vmin;
  column-count: 2;
  column-gap: 6vmin;
  font-weight: 300;
  margin: 0;
}

.academy-side-nav a {
  padding: 1vmin 0vmin 1vmin 2vmin;
  text-decoration: none;
  font-size: 1.8vmin;
  color: #2196f3;
  display: block;
}

.academy-container ul {
  list-style-type: none;
  padding: 0;
}

.academicmain {
  margin-left: 5vw;
  position: relative;
  float: left;
  padding: 0px 10px;
}
.academy-container h2 {
  color: #333;
  font-size: 3.5vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-weight: 500;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .academy-container {
    width: 90vw;
  }

  .academy-side-nav {
    height: 22vmin;
    width: 25vmin;
  }

  .academy-container h2 {
    font-size: 3.5vmin;
  }

  .academy-container b {
    font-size: 2vmin;
  }

  .academy-container li {
    font-size: 2vmin;
  }
}
