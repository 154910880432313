.application-process-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.application-process-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 75vw;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
}

.application-process-container ol {
  list-style: none;
}

.application-process-container li {
  list-style-position: inside;
  line-height: 1.42857143;
  color: #333;
  font-weight: 300;
  font-size: 2.4vmin;
  margin: 0;
  padding: 0;
}

.application-process-container h2 {
  color: #333;
  font-size: 3.8vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-weight: 500;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .application-process-container {
    width: 90vw;
  }
  .application-process-container h2 {
    font-size: 3vmin;
  }

  .application-process-container span {
    font-size: 2.5vmin;
  }
}
