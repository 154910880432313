.user-edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-edit-popup-content {
  top: 5%;
  background-color: #fff;
  padding: 2vh 2vw;
  border-radius: 8px;
  height: 65%;
  width: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-useredit-button {
  position: absolute;
  top: 1vh;
  right: 1vw;
  background: none;
  border: none;
  cursor: pointer;
}

.user-edit-content h1 {
  font-size: 2.4vh;
  margin-bottom: 2vh;
}

.user-edit-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.5vh;
}

.user-edit-form-group label {
  flex: 0 0 40%; /* Adjusts label width */
  font-weight: bold;
  margin-right: 0.5vw; /* Adjust margin as needed */
}

/* .user-edit-form-group span, */
.user-edit-form-group select,
.user-edit-form-group textarea,
.user-edit-form-group input {
  flex: 1;
  padding: 0.8vh 0.8vw;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 0; /* Removed margin */
}

.user-edit-form-group-inner {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1vw; /* Add some space between input and button */
}

.user-edit-form-group textarea {
  height: 10vh; /* Adjust as needed */
}

.user-edit-form-group-inner button {
  padding: 0.8vh 1vw;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-edit-form-group-inner button:hover {
  background-color: #0056b3;
}

.user-edit-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1vw;
  margin-top: 2vh;
}

.user-edit-form-actions button {
  padding: 1vh 2vw;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-edit-form-actions button:hover {
  background-color: #0056b3;
}
