.staff-team-page {
  background-color: white;
  position: relative;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
  width: 100%;
}

.staff-team-container {
  background-color: white;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
}

.wsite-multicol {
  position: relative;
  direction: ltr;
}

.wsite-multicol-table {
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  background-color: transparent;
}

.faculty-team-table {
  width: 100%;
  padding: 0 0 10 0px;
}

.staff-img-container {
  padding: 0 2vmin 2vmin 0;
}

.staff-description {
  padding: 0 2vmin 2vmin 0;
  font-size: 1.8vmin;
  line-height: 1.42857143;
  color: #333;
}

@media screen and (max-width: 768px) {
  .staff-team-container {
    width: 90vw;
  }
}
