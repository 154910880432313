.welcome-letter-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.welcome-letter-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
}
