.class-recurrence-modal {
  inset: 9vh 0 0 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.318);
  display: flex;
  align-items: center;
  justify-content: center;
}

.class-recurrence-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1%;
  width: 38%;
}
.class-recurrence-header h3 {
  font-size: 2vmin;
  font-weight: 600;
}
.class-recurrence-header {
  display: flex;
  justify-content: space-between;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  width: 85%;
}
.class-recurrence-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
}
.class-recurrence-content form {
  width: 100%;
}
.class-time-content,
.recurrence-pattern-content,
.recurrence-range-content {
  border: 1px solid rgb(214, 210, 210);
  margin-bottom: 2vmin;
}
.class-recurrence-modal label {
  font-size: 1.2vmin;
  align-self: center;
}
.class-time-row label {
  display: inline-block;
  width: 3vw;
  align-self: flex-end;
}

.recurrence-range-start label {
  display: inline-block;
  width: fit-content;
  align-self: center;
}

.recurrence-range-end label {
  display: inline-block;
  width: fit-content;
  align-self: center;
  margin-right: 1vmin;
}
.class-recurrence-container legend {
  font-size: 1.2vmin;
}
.class-time-row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1vmin;
}

.class-recurrence-modal .time-zone-picker,
.class-recurrence-modal .time-zone-picker-container {
  width: 10vw;
}

.recurrence-pattern-content,
.recurrence-range-content {
  display: flex;
  flex-direction: row;
}

.recurrence-type-col {
  width: 5vw;
}
.recurrence-range-start {
  width: 15vw;
  height: 3vmin;
  display: flex;
  justify-content: space-evenly;
}

.class-recurrence-buttons {
  margin: 3vmin;
  display: flex;
  justify-content: center;
}

.weekly-choices {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.weekly-choice {
  width: 25%;
}

.radio-choice {
  display: flex;
  margin-bottom: 1.5vmin;
  align-items: center;
}

.class-recurrence-modal select {
  height: 2vmin;
  margin-right: 1vmin;
  font-size: 1.2vmin;
}

.class-recurrence-modal input[type="number"] {
  height: 1.2vmin;
  width: 4vmin;
  font-size: 1.2vmin;
  margin-left: 1vmin;
  margin-right: 1vmin;
}

.class-recurrence-modal input[type="radio"],
.class-recurrence-modal input[type="checkbox"] {
  margin: 0 0.1vmin 0 0;
  height: 1vmin;
  width: 1vmin;
}

.weekly-recurrence,
.yearly-recurrence {
  margin-bottom: 2vmin;
}

@media screen and (max-width: 768px) {
  .class-recurrence-container {
    width: 80%;
  }
}
