.class-request-modal {
  inset: 8vh 0 0 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.318);
  display: flex;
  align-items: center;
  justify-content: center;
}
.class-request-close-icon-button {
  background: none;
  border: none;
  cursor: pointer;
}
.class-request-row .radio-group {
  width: 30vw;
  display: flex;
}
.class-request-row input[type="text"],
.class-request-row input[type="email"],
.class-request-row input[type="tel"] {
  height: 3vmin;
  width: 14.5vw;
  display: flex;
}
.class-request-row .radio-item label {
  width: fit-content;
}

.class-request-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1%;
  width: 55%;
  max-width: 30vw;
  padding: 2vh 2vw;
}
.class-request-header h3 {
  font-size: 2vmin;
  font-weight: 800;
}
.class-request-header {
  display: flex;
  justify-content: space-between;
  margin-top: 2vmin;
  width: 90%;
}

.class-request-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 3vmin;
}
.class-request-content form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
}

.class-request-row {
  display: flex;
  align-items: flex-start;
  height: 4vmin;
  width: fit-content;
}

.class-request-content select {
  font-size: 1.5vmin;
  width: 15vw;
  height: 90%;
}
.class-request-row label {
  display: inline-block;
  width: 8vw;
  font-size: 1.5vmin;
  font-weight: 800;
}

.student-list,
.student-picker {
  width: 15vw;
}

.class-request-save {
  margin: 3vmin;
  display: flex;
  justify-content: space-between;
}
.class-request-save input[type="submit"] {
  background-color: #2196f3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.class-request-save input[type="submit"]:disabled {
  background-color: gray;
  cursor: not-allowed;
}
.normal-schedule {
  margin-right: 1vmin;
}
.normal-schedule-row {
  display: flex;

  gap: 1vmin;
}

/* .class-request-modal .time-zone-picker,
.class-request-modal .time-zone-picker-container {
  width: 10vw;
} */

.normal-schedule-row label {
  display: inline-block;
  width: 5vmin;
  font-size: 1.5vmin;
  font-weight: normal;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .class-request-container {
    width: 100%;
  }
}
