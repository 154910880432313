.steam-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.steam-container {
  background-color: white;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
  display: flex;
}

.steam-side-nav {
  height: 15vmin;
  width: 27vmin;
  position: relative;
  float: left;
  z-index: 0;
  left: 0px;
  background: #eee;
  overflow: hidden;
}

.steam-content {
  margin-left: 5vw;
  position: relative;
  float: left;
  padding: 0px 10px;
}

.two-columns {
  font-size: 1.8vmin;
  column-count: 2;
  font-weight: 300;
  margin: 0;
}

.steam-side-nav a {
  padding: 1vmin 0vmin 1vmin 2vmin;
  text-decoration: none;
  font-size: 1.8vmin;
  color: #2196f3;
  display: block;
}

.steam-container ul {
  list-style-type: none;
  padding: 0;
}

.steam-container h2 {
  color: #333;
  font-size: 3.5vmin;
  margin-top: 2vmin;
  margin-bottom: 1vmin;
  font-weight: 500;
  line-height: 1.1;
}

.steam-content img {
  max-width: 60%;
}

@media screen and (max-width: 768px) {
  .steam-container {
    width: 90vw;
  }

  .steam-side-nav {
    height: 15vmin;
    width: 58vmin;
  }

  .steam-container h2 {
    font-size: 3.5vmin;
  }

  .steam-container b {
    font-size: 2vmin;
  }

  .steam-container li {
    font-size: 2vmin;
  }

  .steam-content img {
    max-width: 80%;
  }
}
