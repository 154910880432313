.forgotpassword-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.forgotpassword-container {
  position: relative;
  background: #fff;
  padding: 2vh 2vw;
  border-radius: 0.8vh;
  width: 30vw;
  box-shadow: 0 0.2vh 1vh rgba(0, 0, 0, 0.1);
}

.forgotpassword-close-icon-button {
  position: absolute;
  top: 1vh;
  right: 1vw;
  background: none;
  border: none;
  cursor: pointer;
}

.forgotpassword-form {
  display: flex;
  flex-direction: column;
}

.forgotpassword-form-group {
  margin-bottom: 1.5vh;
}

.forgotpassword-form-group label {
  display: block;
  margin-bottom: 0.5vh;
}

.forgotpassword-form-group input {
  width: 100%;
  padding: 0.8vh 0.8vw;
  box-sizing: border-box;
}

.forgotpassword-links {
  margin-bottom: 1.5vh;
}

.forgotpassword-links a {
  color: #007bff;
  text-decoration: none;
}

.forgotpassword-links a:hover {
  text-decoration: underline;
}

.forgotpassword-form-actions {
  display: flex;
  justify-content: space-between;
}

.forgotpassword-submit-button,
.forgotpassword-cancel-button {
  padding: 1vh 2vw;
  border: none;
  border-radius: 0.4vh;
  cursor: pointer;
}

.forgotpassword-submit-button {
  width: 100%;
  background-color: #007bff;
  color: white;
}

.forgotpassword-cancel-button {
  background-color: #dc3545;
  color: white;
}

.error {
  color: red;
  font-size: 0.9em;
}
