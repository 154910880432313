.robotics-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.robotics-container {
  background-color: white;
  display: flex;
  height: 90vh;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
  padding: 2vmin 4vmin 4vmin 4vmin;
}

.robotics-container span {
  font-family: Arial, sans-serif;
  font-size: 2.2vmin;
  color: #000;
  font-weight: plain;
  text-decoration: none;
}

.paragraph {
  text-align: left;
  display: block;
}

.wsite-multicol {
  position: relative;
  direction: ltr;
}

@media screen and (max-width: 768px) {
  .robotics-page {
    height: 85vh;
  }

  .robotics-container {
    width: 80vw;
  }
}
