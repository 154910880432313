.user-home body {
  overflow-x: hidden;
}
.unclickable {
  pointer-events: none;
  color: #808080;
}
.user-home {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto; /* Combined margin-left and margin-right */
  padding: 0; /* Ensure no padding */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  width: 100vw;
}

.user-home-table-control {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 45vh;
}

.user-home-table-control th {
  position: sticky;
  top: 0;
}

.user-home table,
.user-home th,
.user-home td {
  font-size: 1.5vmin;
  border: 0.1vmin solid #dee2e6; /* Changed from 1px */
}

.user-home th,
.user-home td {
  padding: 0.8vh; /* Changed from 8px */
  text-align: left;
}
.filter-dropdown input[type="text"] {
  height: 30%;
  width: 5vw;
}

.user-home th {
  background-color: #f8f9fa;
}

.user-home .header {
  background-color: #2a3f54;
  color: white;
  padding: 1vh;
  text-align: center;
}

.content-userhome {
  border-radius: 1vmin;
  background-color: #f2f2f2;
  padding: 0 4vmin 4vmin 4vmin;
  display: flex;
  flex: 1;
}

.sidebar {
  background-color: #f8f9fa;
  border-right: 0.1vmin solid #dee2e6; /* Changed from 1px */
  width: 10vw; /* Changed from 200px */
  padding: 2vh; /* Changed from 20px */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  cursor: pointer;
  padding: 1vh 0; /* Changed from 10px 0 */
  color: #007bff;
  font-size: 2vmin;
  font-weight: bold;
}

.sidebar ul li.active {
  color: black;
}

.sidebar ul li + li {
  margin-top: 5vh; /* Changed from 50px */
}

.sidebar ul li:hover {
  text-decoration: underline;
}

.main-content {
  flex: 1;
  padding: 2vh; /* Changed from 20px */
  background-color: white;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin: 1vh 0; /* Reduced margin from 2vh */
}

.pagination li {
  margin: 0 0.25vw; /* Reduced margin from 0.5vw */
}

.pagination li a {
  display: block;
  padding: 0.5vh 1vw; /* Reduced padding from 0.8vh 1.6vw */
  border: 0.1vmin solid #ddd;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2vmin; /* Reduced font size from 1.6vh */
}

.pagination li a:hover {
  background-color: #eee;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border: 0.1vmin solid #007bff;
}

.pagination li.disabled a {
  color: #ddd;
  cursor: not-allowed;
}

.pagination-controls {
  margin: 0.5vh 0; /* Reduced margin from 1vh */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-controls label {
  font-size: 1.2vmin; /* Reduced font size from 1.6vh */
}

.pagination-controls select {
  font-size: 1.2vmin; /* Reduced font size from 1.6vh */
  padding: 0.5vh; /* Reduced padding from 0.8vh */
  height: 3vh; /* Reduced height from 4vh */
  margin-left: 0.25vw; /* Reduced margin from 0.5vw */
}

.requests {
  margin-bottom: 2vh; /* Changed from 20px */
}

.requests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh; /* Changed from 10px */
  gap: 40vw; /* Add space between header and button */
}

.registered-classes {
  height: fit-content;
  margin-bottom: 2vh; /* Changed from 20px */
}

.registered-classes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh; /* Changed from 10px */
  gap: 20vw; /* Add space between header and button */
}

.requests h2,
.registered-classes h2 {
  margin-bottom: 1vh; /* Changed from 10px */
}

.new-class-request,
.create-new-class {
  padding: 1vh 2vw; /* Changed from 10px 20px */
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.5vmin; /* Changed from 5px */
  width: auto; /* Adjust width to be auto */
  max-width: 20vw; /* Changed from 200px */
  margin-top: 3vh; /* Add margin-top to move the button lower */
}

.new-class-request:hover,
.create-new-class:hover {
  background-color: #0056b3;
}

.iframe-content {
  width: 100%;
  height: 100%;
}

/* Remove down arrow pseudo-elements */
.user-home table th::after {
  display: none;
}

.filter-icon {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .user-home {
    width: 100vw;
    padding: 1vh; /* Changed from 10px */
  }

  .sidebar {
    width: 100%;
    padding: 1vh; /* Changed from 10px */
    border-right: none;
    border-bottom: 0.1vmin solid #dee2e6; /* Changed from 1px */
  }

  .sidebar ul li {
    font-size: 2vmin;
  }

  .sidebar ul li + li {
    margin-top: 3vh; /* Changed from 30px */
  }

  .main-content {
    padding: 1vh; /* Changed from 10px */
  }

  .new-class-request {
    width: 100%; /* Full width on smaller screens */
    padding: 1.5vh 0; /* Changed from 15px */
    text-align: center; /* Center the button text */
  }

  .user-home table,
  .user-home th,
  .user-home td {
    font-size: 1.2vmin;
  }
}
