.tutorial-page {
  background-color: white;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
}

.tutorial-container {
  background-color: white;
  width: 75vw;
  margin-right: auto;
  margin-left: auto;
}

.tutorial-form-container {
  border-radius: 1vmin;
  background-color: #f2f2f2;
  padding: 4vmin;
}

.form-header {
  text-align: left;
  padding: 4vmin;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 2vmin;
  width: 100%;
}

.form-row-header {
  font-weight: bold;
  text-align: left;
  flex: 1; /* Adjust as needed for header width */
  font-size: 2vmin;
}

.form-row-content {
  flex: 3; /* Adjust as needed for content width */
  display: flex;
  align-items: center;
}

.form-row-content label {
  flex: 1;
  margin-right: 4vmin;
}

.form-row-content input[type="text"],
.form-row-content input[type="email"],
.form-row-content input[type="tel"],
.form-row-content select {
  flex: 2;
  padding: 2vmin;
  border: 1px solid #ccc;
  border-radius: 1vmin;
  box-sizing: border-box;
  height: 6vmin;
}

.form-row-content .large-input {
  flex: 3;
}

.tutorial-container .radio-group {
  display: flex;
  align-items: center;
}

.tutorial-container .radio-group input[type="radio"] {
  margin-right: 5px;
}

.tutorial-container .radio-group label {
  margin-right: 15px;
}

.tutorial-container .radio-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.tutorial-container input[type="submit"] {
  background-color: #2196f3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tutorial-form-error {
  color: red;
  display: block;
  font-size: 12px;
}

.tutorial-container input[type="submit"]:disabled {
  background-color: gray;
  cursor: not-allowed;
}
.tutorial-page label {
  font-size: 2vmin;
}

@media screen and (max-width: 768px) {
  .tutorial-container {
    width: 90vw;
  }
}
