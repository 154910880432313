.add-comments-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-comments-container {
  position: relative;
  background: #fff;
  padding: 2vh 2vw;
  border-radius: 8px;
  width: 40vw;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-add-comments-button {
  position: absolute;
  top: 1vh;
  right: 1vw;
  background: transparent;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1.2rem; /* Increase the size of the close button */
}

.add-comments-container h2 {
  font-size: 2vh;
  margin-bottom: 2vh;
}

.add-comments-container textarea {
  width: 90%;
  height: 20vh;
  padding: 1vh 1vw;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 2vh;
}

.save-comment-button {
  padding: 1vh 2vw;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-comment-button:hover {
  background-color: #0056b3;
}
