@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.cards {
  height: 100vmin;
  display: flex;
  justify-content: center;
  padding-bottom: 5vh;
}

.card-row {
  margin-top: 50vh;
  width: 75vw;
  display: flex;
  justify-content: space-evenly;
}

.card {
  border-radius: 2%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 20px 30px 20px 30px;
  color: #fff;
  cursor: pointer;
  text-align: left;
  font-family: Camphor, "Open Sans", "Segoe UI", sans-serif !important;
  position: relative;
  overflow: hidden;
  animation: slideInFromLeft 0.5s ease-out forwards;
  opacity: 0;
}

.card:nth-child(2) {
  animation-delay: 0.3s;
}

.card:nth-child(3) {
  animation-delay: 0.5s;
}

.card:nth-child(4) {
  animation-delay: 0.7s;
}

.card h4 {
  color: #ffffff;
  font-size: 3vmin;
  font-weight: normal;
  text-transform: uppercase;
  height: 11vmin;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}

.card p {
  color: #f6f9fa;
  line-height: 2.5vmin;
  letter-spacing: 0.05vmin;
  height: 12vmin;
  font-size: 2vmin;
  margin-bottom: 9vmin;
  font-weight: 300;
}

.card h6 {
  font-size: 1.5vmin;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.card a,
.welcome-table-col a {
  text-decoration: none;
  color: #337ab7;
}
.welcome-table-col a {
  font-size: 1.5vmin;
  line-height: 1.42857143;
}

.card h4::after {
  content: "";
  display: block;
  height: 0.3vmin;
  background: #fbff01;
  position: absolute;
  transition: width 0.7s ease, background-color 0.5s ease;
  width: 0;
}
.card h6::after {
  content: "";
  display: block;
  height: 0.3vmin;
  background: #fffffe;
  position: absolute;
  transition: width 0.7s ease, background-color 0.5s ease;
  width: 10px;
}

.card:hover h4::after {
  width: calc(75%);
  transition-delay: 0.1s;
}

.card:hover h6::after {
  width: calc(15%);
  background: #fbff01;
  transition-delay: 0.1s;
}

.card:hover span {
  color: #fbff01;
  transition-delay: 0.1s;
}

.mission-display {
  background-color: #f6f6f6;
  width: 100%;
  padding: 8vmin 0;
  height: 30vh;
}

.mission-container {
  width: 80vw;
  padding: 0 4vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.welcome-display {
  background-color: #ffffff;
  width: 100%;
  padding: 8vmin 0;
}

.welcome-container {
  width: 80vw;
  padding: 0 4vmin;
  margin-right: auto;
  margin-left: auto;
}
.welcome-table {
  position: relative;
  direction: ltr;
}
.welcome-table table {
  display: table;
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.welcome-table-col img {
  border-width: 0;
  max-width: 50vmin;
}

.welcome-table-col-paragraph {
  font-family: Arial, sans-serif;
  font-size: 2vmin;
  line-height: 1.6;
  text-align: left;
  display: block;
}

.staff-display {
  background-color: #f6f6f6;
  width: 100%;
  padding: 8vmin 0;
}

.staff-display-container {
  width: 80vw;
  padding: 0 4vmin;
  margin-right: auto;
  margin-left: auto;
}

.staff-display-title {
  font-family: ProximaNova-Semibold;
  font-size: 5vmin;
  font-weight: 600;
}

.staff-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: -2vmin;
  margin-top: 6vmin;
}
.staff-col {
  flex: 1;
  max-width: 25%;
  padding: 0 4vmin;
}
.staff-card {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
}

.staff-card div:first-child {
  padding: 3vmin 0;
  border-radius: 6px;
  background: #fff;
}

.staff-card div:first-child img {
  max-width: 100%;
  border-radius: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

.card {
  visibility: visible;
}

.staff-name {
  font-size: 1.8vmin;
}

@media screen and (max-width: 768px) {
  .cards {
    height: 110vmin;
    display: flex;
    justify-content: center;
    padding-bottom: 5vh;
  }
  .card-row {
    margin-top: 2vh;
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .staff-name {
    font-size: 1.2vmin;
  }

  .welcome-table-col {
    margin-left: 6vmin;
  }
  .welcome-table-col img {
    border-width: 0;
    max-width: 40vmin;
  }

  .welcome-container {
    width: 90vw;
    padding: 0 4vmin;
  }

  .staff-col {
    flex: 1;
    max-width: 25%;
    padding: 0 1vmin;
  }

  .staff-display-container {
    width: 90vw;
    padding: 0 2vmin;
    margin-right: auto;
    margin-left: auto;
  }

  .mission-container {
    width: 90vw;
    padding: 0 4vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }
}
